import { Pencil, ReceiptText, Store, } from 'lucide-react';
import { Button, Space, Table, Tag, Tooltip } from 'antd'
import { MdOutlineLocalShipping } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { BsWhatsapp } from 'react-icons/bs';
import moment from 'moment';

import { cn, sendWhatsAppMessage } from '../../../libs/utils';
import { STATUSES } from '../../../constants/status';
import { useUiContext } from '../../../context/ui';
import { IOrder } from '../../../interfaces/order';

interface OrderTableProps {
  data: IOrder[];
  isLoading: boolean;
  pages?: number;
  currentPage?: number;
  onPageChange?: any;
  onPageSizeChange?: any;
  pageSize?: number;
}


export const OrderTable = ({
  data = [],
  isLoading,
  pages = 0,
  currentPage = 0,
  onPageChange,
  onPageSizeChange,
  pageSize = 0,
}: OrderTableProps) => {
  const { setShowOrderModal, setSetshowInvoiceModal } = useUiContext()
  const navigate = useNavigate()

  const columns: ColumnsType<IOrder> = [
    {
      title: 'Nro',
      dataIndex: 'uniqueID',
      key: 'uniqueID',
      width: 90,
      render: (value, record) => (<span className={cn(`border-${record?.fleet}-500`)}>#{value}</span>)
    },
    {
      width: 85,
      align: 'center',
      title: 'Metodo',
      key: 'shipping',
      dataIndex: 'shipping',
      render: (value) => {
        let text = value === 'DELIVERY' ? 'Delivery' : 'Retiro';
        let color = value === 'DELIVERY' ? '#87d068' : '#2db7f5';
        let icon = value === 'DELIVERY' ? <MdOutlineLocalShipping color={color} size={24} /> : <Store color={color} size={22} />;
        return (
          <Tooltip title={text}>
            {icon}
          </Tooltip>
        )
      }
    },
    {
      dataIndex: 'username',
      title: 'Cliente',
      key: 'username',
      ellipsis: true,
      width: 200,
    },
    {
      align: 'center',
      title: 'Monto',
      dataIndex: 'total',
      key: 'total',
      width: 120,
      render: (total: number) => (
        <div>${total.toLocaleString('es-AR')}</div>
      )
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      width: 180,
    },
    {
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY, h:mm:ss a'),
      title: 'Fecha y Hora',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 200,
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
    },
    {
      align: 'center',
      title: 'Estado',
      width: 150,
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        let text = STATUSES.find(status => status.value === value)?.label;
        let color = STATUSES.find(status => status.value === value)?.color;
        return (<Tag color={color}>{text?.toUpperCase()}</Tag>)
      }
    },
    {
      align: 'center',
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      render: (_, record) => (
        <Space>
          <Tooltip title="Chatear por consulta">
            <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="default" shape="circle" icon={<BsWhatsapp size={18} />} onClick={() => sendWhatsAppMessage(record)} data-tut="ten-step" />
          </Tooltip>
          <Tooltip title="Editar">
            <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="text" shape="circle" icon={<Pencil size={18} />} onClick={() => setShowOrderModal({ visible: true, order: record })} data-tut="eleven-step" />
          </Tooltip>
          <Tooltip title="Detalles">
            <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="primary" shape="circle" icon={<ReceiptText size={18} />} onClick={() => navigate(`/order/${record._id}`)} data-tut="twelve-step" />
          </Tooltip>
          <Tooltip title="Enviar un correo">
            <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="link" shape="circle" icon={<AiOutlineMail size={18} onClick={() => setSetshowInvoiceModal({ visible: true, order: record })} data-tut="thirteen-step" />} />
          </Tooltip>
        </Space>
      )
    },
  ];

  return (
    <Table
      className='rounded-md shadow-md'
      data-tut="nine-step"
      loading={isLoading}
      scroll={{ x: 800 }}
      columns={columns}
      dataSource={data}
      rowClassName={(row) => row?.fleet ? `ant-table-row-${row?.fleet}` : ''}
      rowKey='_id'
      pagination={{
        className: 'section-not-print px-4',
        rootClassName: '',
        locale: {
          items_per_page: 'x pág.',
        },
        total: pageSize * pages,
        current: currentPage,
        onChange: onPageChange,
        onShowSizeChange: onPageSizeChange,
        showSizeChanger: true
      }}
    />
  )
}
