import { createContext, useContext, useEffect, useState } from 'react'

import { IOrder } from '../interfaces/order'
import { getStats } from '../api/stats'
import { Pagination, getOrders } from '../api/orders'

interface OrderProviderProps {
  children: JSX.Element | JSX.Element[]
}

interface OrderStats {
  cancelled: number
  delivered: number
  pending: number
  today: number
}

interface OrderContextProps {
  stats: OrderStats
  total: number
  page?: number
  limit?: number
  orders: IOrder[]
  loading: boolean
  handleSearch: (term: string) => void
  handleDateChange: (date: any) => void
  onPageChange: (page: number) => void
  hadleGetOrders: (query?: Pagination) => void
  onPageSizeChange: (current: number, size: number) => void
}

const Context = createContext<OrderContextProps | undefined>(undefined)

export const useOrderContext = (): OrderContextProps => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Order context debe ser utilizado dentro de un provider.');
  }
  return context;
};

export const OrderProvider = ({ children }: OrderProviderProps) => {
  const [stats, setStats] = useState({
    cancelled: 0,
    delivered: 0,
    pending: 0,
    today: 0,
  })

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [query, setQuery] = useState<Pagination>({ limit, page, term: '' })


  const disabledDate = (current: any) => {
    return current && current > new Date();
  };

  const hadleGetOrders = (query?: Pagination) => {
    setLoading(true)
    handleGetStats()
    getOrders(query)
      .then(({ data }) => {
        const { limit, skip, total } = data.metadata
        setOrders(data.results)
        setLimit(limit)
        setPage(skip)
        setTotal(total)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }


  const handleGetStats = () => {
    setLoading(true)
    getStats()
      .then(({ data }) => {
        setStats(data)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const onPageChange = (page: number) => {
    setPage(page);
    setQuery((prevQuery) => ({ ...prevQuery, page }));
  };

  const onPageSizeChange = (current: any, size: any) => {
    setLimit(size);
    setQuery((prevQuery) => ({ ...prevQuery, limit: size }));
  };

  const handleSearch = (term: string) => {
    setQuery((prevQuery) => ({ ...prevQuery, term, page }));
  };

  const handleDateChange = (date: [Date, Date]) => {
    if (!date) return setQuery((prevQuery) => ({ limit, page, term: '' }));
    const start_date = new Date(new Date(date[0]).setHours(0, 0, 0, 0)).toISOString() || ''
    const end_date = new Date(new Date(date[1]).setHours(23, 59, 59, 999)).toISOString() || ''
    setQuery((prevQuery) => ({ ...prevQuery, page, start_date, end_date }));
  };

  useEffect(() => {
    hadleGetOrders(query)
  }, [query])

  const orderContextValue: OrderContextProps = {
    stats,
    page,
    limit,
    orders,
    total,
    loading,
    handleSearch,
    handleDateChange,
    onPageChange,
    hadleGetOrders,
    onPageSizeChange,
  }

  return (
    <Context.Provider value={orderContextValue}>
      {children}
    </Context.Provider>
  )
}
