import {
  Breadcrumb,
  Button,
  Card,
  Descriptions,
  Form,
  List,
  Table,
  Tag,
} from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft, Loader2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import moment from 'moment'

import { IOrder } from '../../../interfaces/order'
import { getOrdersById } from '../../../api/orders'
import { ColumnsType } from 'antd/es/table'
import { IProduct } from '../../../interfaces/product'
import { IOption } from '../../../interfaces/options'
import { formatPrice, formatSubtotalPrice, renderPaymentMethod } from '../../../libs/utils'
import { CardForm } from '../../../components/form/order/card-form'
import { FLEETS } from '../../../constants/fleets'
import { OPERATOR } from '../../../constants/operator'
import { useOrderContext } from '../../../context/order'

import "../../../styles/global.css"

const columns: ColumnsType<IProduct> = [
  {
    dataIndex: 'product_code',
    title: 'Código',
    key: 'product_code',
    width: 50,
    render: (_, { product_code }) => <p className="text-lg">#{product_code}</p>,
  },
  {
    dataIndex: 'title',
    title: 'Nombre',
    key: 'title',
    render: (_, { title }) => <p className="text-lg">{title}</p>,
  },
  {
    dataIndex: 'weight',
    title: 'Peso',
    key: 'Weight',
    width: 120,
    align: 'center',
    render: (_, { weight }) => (
      <p className="text-lg">
        {weight !== undefined ? `${weight.toFixed(1)} kg/s.` : 'No disponible'}
      </p>
    ),
  },
  {
    dataIndex: 'stock',
    align: 'center',
    title: 'Unidad',
    key: 'stock',
    width: 50,
    render: (_, { stock }) => {
      let text = stock === 'KILOGRAM' ? 'Kg.' : 'Ud.'
      return <p className="text-lg">{text}</p>
    },
  },
  {
    dataIndex: 'title',
    title: 'Opciones',
    key: 'title',
    render: (_, { options }) => {
      return options?.map(({ name, _id }: IOption) => (
        <Tag key={_id} className="text-sm">
          {name}
        </Tag>
      ))
    },
  },
  {
    dataIndex: 'is_offer_quantity',
    title: 'Oferta por cantidad',
    key: 'is_offer_quantity',
    align: 'center',
    width: 160,
    render: (is_offer_quantity) => {
      let color = is_offer_quantity ? 'cyan' : 'volcano'
      let text = is_offer_quantity ? 'Si' : 'No'
      return (
        <Tag color={color} className="text-sm">
          {text}
        </Tag>
      )
    },
  },
  {
    dataIndex: 'is_offer',
    title: 'Oferta',
    key: 'is_offer',
    align: 'center',
    width: 60,
    render: (_, { is_offer }) => {
      let color = is_offer ? 'cyan' : 'volcano'
      let text = is_offer ? 'Si' : 'No'
      return (
        <Tag color={color} className="text-sm">
          {text}
        </Tag>
      )
    },
  },
  {
    dataIndex: 'quantity',
    title: 'Cantidad',
    key: 'quantity',
    align: 'center',
    width: 30,
    render: (_, { stock, quantity }) => {
      let text = stock === 'KILOGRAM' ? 'Kg.' : 'Ud.'
      return <p className="text-lg">{`${quantity} ${text}`}</p>
    },
  },
  {
    dataIndex: 'offer_quantity_price',
    title: 'Precio cantidad',
    align: 'center',
    key: 'offer_quantity_price',
    width: 80,
    render: (offer_quantity_price) => <p className="text-lg">${offer_quantity_price}</p>,
  },
  {
    dataIndex: 'price',
    title: 'Precio',
    align: 'center',
    key: 'price',
    width: 80,
    render: (price) => <p className="text-lg">${price}</p>,
  },
  {
    dataIndex: 'subtotal',
    title: 'subtotal',
    align: 'center',
    key: 'subtotal',
    width: 80,
    render: (_, { price, quantity }) => (
      <p className="text-lg">${price * quantity!}</p>
    ),
  },
]

export const OrderDetails = () => {
  const [form] = Form.useForm()
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<IOrder>()

  const { hadleGetOrders, page } = useOrderContext()

  const fetchOrder = async () => {
    if (!id) return
    setLoading(true)
    try {
      const { data } = await getOrdersById(id)
      form.setFieldsValue(data)
      setOrder(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOrder()
  }, [id])

  const handleUpdate = () => {
    fetchOrder()
    hadleGetOrders({ page })
  }

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-10 w-10" />
      </div>
    )

  if (!order) navigate('/')

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <Breadcrumb className="section-not-print">
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Orden</Breadcrumb.Item>
        <Breadcrumb.Item>{order?.uniqueID}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="flex items-center justify-between space-y-2"
        id="section-not-print"
      >
        <Link className="flex gap-2" to="/">
          <ArrowLeft />
          Volver
        </Link>
        <div className="flex items-center space-x-2">
          <Button onClick={() => window.print()}>Imprimir</Button>
        </div>
      </div>
      <CardForm form={form} id={id} onUpdate={handleUpdate} />
      <Card
        className="shadow-md"
        style={{
          borderTop: `8px solid ${FLEETS.find((f) => f.value === order?.fleet)?.color
            }`,
        }}
      >
        <div className="flex flex-row items-center justify-between space-y-0 pb-2 text-lg font-bold">
          Pedido N° {order?.uniqueID}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 grid-details">
          <div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Cliente:</span>
              <p className="text-lg text-muted-foreground">
                {order?.username ? order.username : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Correo:</span>
              <p className="text-lg text-muted-foreground">
                {order?.email ? order.email : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Domicilio:</span>
              <p className="text-lg text-muted-foreground">
                {order?.address
                  ? `${order.address} ${order?.reference && order?.reference}`
                  : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Piso/Depto/Lote</span>
              <p className="text-lg text-muted-foreground">
                {order?.reference ? order.reference : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Localidad:</span>
              <p className="text-lg text-muted-foreground">
                {order?.address_1 ? order.address_1 : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Celular:</span>
              <p className="text-lg text-muted-foreground">
                +{order?.phone ? order.phone : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Nota:</span>
              <p className="text-lg text-muted-foreground">
                {order?.notes ? order.notes : '---------'}
              </p>
            </div>
          </div>
          {/* ------- */}
          <div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Número de operario:</span>
              {order?.operator_number ? order.operator_number : '---------'}
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Tipo de canasto:</span>
              {order?.basket_type
                ? OPERATOR.find((o) => o.value === order.basket_type)?.label
                : '---------'}
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Para:</span>
              <p className="text-lg text-muted-foreground">
                <Tag color="blue">
                  {order?.shipping === 'DELIVERY' ? 'DELIVERY' : 'RETIRAR'}
                </Tag>
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Método de pago:</span>
              <p className="text-lg text-muted-foreground">
                {renderPaymentMethod(order?.payment_option!)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Fecha de alta:</span>
              <p className="text-lg text-muted-foreground">
                {moment(order?.createdAt).format('DD/MM/YYYY, h:mm:ss a')}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Fecha de entrega:</span>
              <p className="text-lg text-muted-foreground">
                {order?.deadline
                  ? moment(order?.deadline).format('DD/MM/YYYY, h:mm:ss a')
                  : '---------'}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-lg">Cupón Aplicado:</span>
              <p className="text-lg text-muted-foreground">
                {order?.coupon ? order?.coupon.name : '---------'}
              </p>
            </div>
          </div>
        </div>
      </Card>
      <List
        header={
          <Descriptions
            extra={`(${order?.items ? order.items.length : 0}) items`}
            title={`Detalles del pedido Nro. #${order?.uniqueID}`}
            className="pt-3"
          />
        }
        footer={
          <Descriptions column={{ xs: 1, sm: 2, lg: 4 }} className='mt-4'>
            <Descriptions.Item label="Subtotal">
              ${order?.sub_total ? order.sub_total.toLocaleString('es-AR') : 0.00}
            </Descriptions.Item>
            <Descriptions.Item label="Descuentos">
              ${order?.discount ? order.discount : 0.00}
            </Descriptions.Item>
            <Descriptions.Item label="Envío">$0.00</Descriptions.Item>
            <Descriptions.Item label="Total">
              ${order?.total ? order.total.toLocaleString('es-AR') : 0.00}
            </Descriptions.Item>
          </Descriptions>
        }
        children={
          <Table
            dataSource={order?.items}
            pagination={false}
            columns={columns}
            scroll={{ x: 800 }}
            rowKey="_id"
          />
        }
        style={{ marginBottom: '20px' }}
        itemLayout="vertical"
        bordered
      />
    </div>
  )
}
